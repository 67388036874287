










import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Swiper from "swiper";

import { projects } from "@/mock/projects";

@Component({
  components: {
    ProjectsSwiperSlide: () =>
      import(
        /* webpackChunkName: 'projects-gallery-slide' */ "@/components/project/ProjectsSwiperSlide.vue"
      )
  }
})
export default class ProjectsSwiperGallery extends Vue {
  /** Swiper instance */
  swiper!: Swiper;

  /** Available projects */
  projects = projects;

  mounted(): void {
    this.swiper = new Swiper(this.$refs.swiperContainer as HTMLElement, {
      direction: "horizontal",
      speed: 1000,
      loop: true,
      grabCursor: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false
      }
    });
  }

  beforeDestroy(): void {
    this.swiper.destroy(true, true);
  }
}
